<template>
  <div>
    <TabMenu/>
    <!--    내 정보 수정-->
    <div>
      <!-------------------내 정보 수정 모달창------------------->
      <div class="modal" v-if="showModal">
        <!-- 사이트 이탈 Modal의 내용 -->
        <div class="modal_contents">
          <div class="modal_close close">
            <a @click="closeModal">close</a>
          </div>
          <p class="modal_tit">현재 보고 계신<br> <strong>BMSON</strong> 웹사이트를 나갑니다.</p>
          <p class="modal_exp">현재 웹사이트를 나와 다른 외부 웹사이트와 연결됩니다. BMSON은 외부 사이트의 콘텐츠에 대한 책임을 지지 않으며, 해당 콘텐츠로 인한 손해나 손상에
            대한 법적 책임이 없습니다. 외부 사이트 링크는 단지 웹사이트 사용자들에게 편의를 제공하기 위함입니다.</p>
          <div class="modal_btn">
            <span class="btn_modal" @click="closeModal({url: url})">계속하기</span>
            <span class="btn_modal" @click="closeModal">취소</span>
          </div>
        </div>
      </div>
      <!-------------------내 정보 수정 모달창------------------->
    </div>
  </div>
</template>

<script>
import TabMenu from "@/components/MyMenu/TabMenu";

export default {
  name: "ModifyInformation",
  components: {
    TabMenu
  },
  data() {
    return {
      member: {},
      isLogin: false,
      showModal: false,
    }
  },
  created() {
    window.location.href = this.url
    // this.getMember()
    // this.checkLogin()
    // document.documentElement.classList.add("overflow-y-hidden");
  },
  computed: {
    url() {
      return `https://www.wwhcpreg.com/protected/bmsonkr_profileupdate?redirectUrl=${ window.location.origin }/my-page/pre-registration`
    },
  },
  methods: {
    getMember() {
      this.member = this.$store.getters.getMember
    },
    async checkLogin() {
      this.isLogin = await this.$store.dispatch('checkLogin')
    },
    closeModal({url}) {
      if (url) {
        window.open(url, '_blank')
      }
      document.documentElement.classList.remove("overflow-y-hidden");
      this.showModal = false;
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
</style>
